body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Roboto', sans-serif;
    background-color: #22222a;
    position: relative;
}

img{
    max-width: 100%;
}

.body-line-wrapper{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;

    .line{
        flex: 1 1 16.6666%;
        border-right: 1px solid rgba(255, 255, 255, 0.05);
    }
}

main{
    min-height: 90vh;

}