/**!  Navs **/
.nav-social{
    .nav-link{
        color: #fff;
        font-size: 24px;
        padding: 4px 5px; 
    }

    .nav-link:hover, .nav-link:focus{
        color: $primary;
    }
}

/**!  Dropdown **/
.dropdown{
    text-transform: uppercase;
    color: #fff;
    margin-right: 25px;
    font-size: 14px;
    font-weight: 700;
    padding-left: 8px;
    padding-right: 8px;

    .dropdown-menu{
        left: 0;
        right: 0;
        min-width: 100%;
        max-width: auto;
        background-color: $primary;
        top:100%!important;
        transform: translateX(0%)!important;
        border-radius: 0px;
        padding: 0;
        margin: 0;

        .dropdown-item{
            text-align: center;
            padding-left: 0;
            padding-right: 0;
            color: #fff;
            text-transform: uppercase;
            font-size: 13px;
            width: auto;
            padding: 8px;
            font-weight: 700;
            transition: all 0.3s;
        }

        .dropdown-item:hover, .dropdown-item:focus{
            background-color: #191920;
            color: #fff;
        }
    }
}


.dropdown-lang .dropdown-toggle::after{
    content: "\e911";
    font-family: 'icomoon' !important;
    border:0px;
    width: auto;
    height: auto;
    font-size: 6px;
}


/**!  Brand **/
.brand{
    text-transform: uppercase;
    font-weight: 900;
    color: #fff;
}


/**!  Header **/
header{
    display: block;
    position: relative;
   
}

.header-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-group{
    display: flex;
    align-items: center;
}
.header-brand{
    padding-left: 35px;
}

.nav-main{
    padding: 60px 0;
    position: relative;

    &:before{
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 100px;
        height: 10px;
        background-color: $primary;
        margin-left: -50px;
    }

    .nav-link{
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        padding-top: 15px;
        padding-bottom: 15px;
        text-transform: uppercase;
    }

    .nav-link:hover{
        color: $primary;
    }

    .dropdown{
        margin-right: 0;
    }

    .dropdown-menu{
        top: 100%!important;
        left: 50%!important;
        right: auto;
        transform: translateX(-50%)!important;
        min-width: auto;
    }

    .dropdown-menu.show{
        display: flex;
    }

    .dropdown-toggle::after{
        content: none;
    }

    .dropdown-toggle::before{
        content: "\e5c5";
        line-height: 1;
        font-family: 'icomoon' !important;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 4px);
        font-size: 28px;
        color: #fff;
        opacity: 0;
        visibility: hidden;
    }

    .nav-item.dropdown:hover .dropdown-toggle::before{
        opacity: 1;
        visibility: visible;
    }
}

.header-mobile{
    display: none;
}


@include media-breakpoint-down(md) { 
    header{
        transition: all 0.3s;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #22222a;
        z-index: 999;
        display: block;
        transform: translate3d(100%, 0, 0);
       
        .brand{
            display: none;
        }

        .header-row{
            display: flex;
            flex-direction: column;
            min-height: 90vh;
            align-items: center;
            justify-content: center;
        }

        .nav-main{
            display: block;
            text-align: center;
        }

        .nav-main:before{
            content: none;
        }

        .header-group{
            justify-content: center;
        }

        .nav-main .dropdown-menu{
            position: static;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            transform: translateX(0%)!important; 
            display: none;
        }

        .nav-main .dropdown-menu.show{
            display: block;
        }
    }

    .header-mobile{
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        z-index: 9999;
        background-color: #000;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 20px;

        h4{
            margin: 0;
            line-height: 1;
        }

        .header-brand{
            padding-left: 0;
        }
        #toggleNav{
            outline: 0px!important;
        }
    }

    header.show{
        transform: translate3d(0%, 0, 0);
    }
}