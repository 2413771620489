
/**!  Section  **/
.section-title{
    display: flex;
    align-items: center;
    h2{
        display: inline-block;
    }

    .title-label{
        margin-left: 50px;
        margin-bottom: 100px;
    }
}

/**!  Section scroll  **/
.section-scroll{
    background-color: #202028;
    text-align: center;
    padding: 85px 0 65px 0; 

    a{
        
    }

    .inner-link{
        display: inline-block;
        position: relative;

        .text{
            font-weight: 300;
            font-size: 12px;
            color: #fff;
            text-transform: uppercase;
            transition: all 0.3s;
        }

        [class^="icon"]{
            position: absolute;
            color:#fff;
            transition: all 0.3s;
            font-size: 24px;
            left: 0;
            right: 0;
            bottom: -25px;
            text-align: center;
        }
    }

    a:hover{
        .text, [class^="icon"]{
            color: $primary;
        }
    }
}

/**!  Solution section **/
.section-solution{
    padding: 110px 0;

    .section-title{
       //margin-left: calc( (16.66666% + 30px) * -1);
    }
}

.solution-row{
    display: flex;
    
    .solution-column{
        padding: 0 30px;
        flex: 1 1 50%;
    }
}

.solution-row .solution-column:nth-child(1){
    padding-top: 0px;
}

.solution-row .solution-column:nth-child(2){
    padding-top: 0px;
    transform: translateY(-320px);
}
.solution-item{
    position: relative;

    .solution-image-wrapper{
        position: relative;
        display: block;

        &:after{
            content: "";
            position: absolute;
            z-index: -1;
            left: 10%;
            right: 10%;
            bottom: 0px;
            height: 40%;
            box-shadow: 0px 2px 40px 4px rgba(0, 0, 0, 0.55);
        }

        .btn-solution{
            position: absolute;
            right: 0;
            bottom: 0;
            transform: translate(-20px, 100%);
            min-width: 175px;
            opacity: 0;
            transition: all 0.3s;
        }
    }

    .solution-image{
        position: relative;
        overflow: hidden;
        padding-bottom: 72.5%;
    }
    .solution-bg{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-size: cover;
        background-position: 50%;

        &:after{
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(233, 51, 57, 0);
            transition: all 0.3s;
        }
    }

    .solution-title{
        position: relative;
        padding-left: 85px;
        margin-top: 45px;
        padding-top: 5px;
        padding-bottom: 20px;
        &:after{
            content: "";
            position: absolute;
            left: 85px;
            bottom: 0;
            width: 108px;
            height: 1px;
            background-color: $primary;
        }

        h5{
            margin: 0;
            line-height: 1.2;
            font-weight: 900;
            text-transform: uppercase;
        }

        .solution-icon{
            position: absolute;
            left: 0;
            top: 0;
            color: $primary;
            font-size: 60px;
            [class^="icon"]{
                line-height: 1;
            }
        }
    }
    
    .solution-content{
        padding: 25px 85px;
        padding-right: 0;
        p{
            font-size: 13px;
        }
    }
}

.solution-item .solution-image-wrapper:hover{

    .btn-solution{
        opacity: 1;
        transform: translate(-20px, 50%);
    }
    
    .solution-bg:after{
        background-color: rgba(233, 51, 57, 0.6);
    }
}


/**!  Page header **/
.page-header{
    position: relative;
    z-index: 10;
    overflow: hidden;
    padding-top: 185px;

    .page-header-bg{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 720px;
        background-size: cover;
        background-position: 50%;
    }

    .page-header-title{
        margin: 0;
        margin-bottom: 60px;
    }

}

/**!  Solution nav **/
.solution-single-nav{
    position: absolute;
    top: 300px;
    z-index: 10;

    .slide-nav{
        position: absolute;
        white-space: nowrap;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #191920;
        font-size: 14px;
        color: #fff;
        font-weight: 300;
        min-width: 230px;
        transition: all 0.35s;
        padding: 0 10px;
    }
   

    .slide-icon{
        color: #fff;
        font-size: 18px;
        width: auto;
        height: auto;
        background-color: #191920;
        padding: 25px 15px;
        position: relative;
        z-index: 20;
        transition: all 0.35s;
    }
}

.solution-prev-btn{
    left: 0;

    .slide-nav{
        left: 100%;
        margin-left: 4px;
        transform: translateX(-100%);
    }

    &:hover{
        .slide-nav{
            transform: translateX(0%);
        }
    }
}

.solution-next-btn{
    right: 0;

    .slide-nav{
        right: 0%;
        margin-right: 56px;
        transform: translateX(100%);
    }

    &:hover{
        .slide-nav{
            transform: translateX(0%);
        }
    }
}

.solution-single-nav:hover{
    .slide-icon{
        background-color: $primary;
    }
}

.section-solution-page{
    margin-top: 0px;
    padding-top: 0;

    .section-title{
        padding: 120px 0;
    }
}

/**!  Adventages section **/
.section-advantages{
    margin-bottom: 120px;
    position: relative;

    &:before{
        content: "";
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        bottom: 20%;
        width: 60%;
        background-color: $primary;

    }
}
.advategas-row{
    margin-right: -10px;
    margin-left: -10px;
    list-style: none;
    padding: 0;
    -webkit-transform: translate3d(0px, 0px, 0px);
    li{
        margin-bottom: 20px;
    }
}

.section-advantages .section-title{
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    .title-wrapper{
        margin-bottom: 25px;
    }
}

.advategas-row > .col, .advategas-row > [class*="col-"] {
    padding-right: 10px;
    padding-left: 10px;
}
.advategas-item{
    background-color: #191920;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    text-align: center;
    height: 100%;
    padding: 60px 35px;
    position: relative;
    z-index: 44;
    
    .adventages-icon{
        margin-bottom: 25px;
        font-size: 62px;
        color: $primary;
        
        [class^="icon"]{
            line-height: 1;
        }
    }

    .adventages-content{
        p{
            margin: 0;
        }
    }
}

/**!  For whom section **/
.section-for-whom{
   
}

.fw-image-wrapper{
    
}
.fw-image-wrapper-outer{
    -webkit-transform: translate3d(0px, 0px, 0px);
}




.fw-image{
    position: relative;
    overflow: hidden;
    padding-bottom: 72.5%;
   
    
    .fw-image-bg{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-size: cover;
        background-position: 50%;
    }
}

.fw-row{
    position: relative;
    padding-left: 33.33333%;
    transform: translateY(-110px);
}

.fw-btn{
    position: absolute;
    left: 15%;
    bottom: 0;
    transform: translateY(50%);
}

.fw-menu-wrapper{
    
}

.fw-menu{
    position: absolute;
    z-index: 22;
    left: 0;
    min-width: 50%;
    top: 50%;
    transform: translateY(-50%);
    background-color: $primary;
    ul{
        list-style: none;
        padding: 40px 0px;
        display: block;
    }

    h4, h6{
        line-height: 1.2;
        margin: 0;
        white-space: nowrap;
    }
    h4{
        font-weight: 400;
    }
    h6{
        font-weight: 300;
    }

    li a{
        background-color: $primary;
        
        position: relative;

        &:after{
            content: "";
            position: absolute;
            left: 10%;
            bottom: 7px;
            height: 1px;
            width: 115px;
            background-color: #fff;
        }
    }

    li a.active{
        background-color: #191920;
    }

    li a:hover:not(.active){
        background-color: rgba(25, 25, 32, 0.8);
    }

    li > a{
        display: block;
        padding: 12px 10% 15px 10%;
    }
}


#fw-page{
    .fw-menu ul li a{
        padding: 18px 10% 22px 10%;
    }

    .fw-menu li a:after{
        bottom: 10px;
    }
}

.fw-collapse-group{
    .boxed-secoundary-wrapper{
        padding-top: 0;
    }

    .fw-collapse{
        visibility: hidden;
        height: 0px;
    }

    .fw-collapse.active{
        visibility: visible;
        height: auto;
    }
    
    .fw-collapse{
        h2{
            opacity: 0;
            transform: translateX(-100px);
            transition: all 0.35s;
        }

        p{
            opacity: 0;
            transform: translateX(-50px);
            transition: all 0.4s 0.2s;
        }
    }

    .fw-collapse.active{
        h2{
            opacity: 1;
            transform: scale(1);
        }
        p{
            opacity: 1;
            transform: translateX(0px);
        }
    }
}



/**!  Letters **/
.section-letter-m{
    padding-top: 135px;
    margin-top: 40px;
    overflow: hidden;
    
    .letter{
        position: absolute;
        z-index: 5;
        top: -135px;
        width: 50%;
        left: 0;
        transform: translateX(-60%);
        opacity: 0.6;
    }
}

.section-letter-l{
    overflow: hidden;

    .boxed-secoundary-wrapper{
        padding-bottom: 200px;
    }
    
    .letter{
        position: absolute;
        z-index: -1;
        bottom: 0;
        width: 50%;
        left: 100%;
        transform: translateX(-18%);
        opacity: 0.6;
    }
}

.letter-home-m{
    .boxed-primary{
        min-height: auto;
        padding-bottom: 50px;
    }
}

.letter-home-l{
    position: relative;
    overflow: hidden;

    .letter{
        position: absolute;
        z-index: -1;
        bottom: 240px;
        width: 50%;
        left: 100%;
        transform: translateX(-10%);
        opacity: 0.6;
    }
}


/**!  Swiper **/
.swiper-button-prev, .swiper-button-next{
    background-image: none;
    font-family: 'icomoon' !important;
    color: #fff;
    font-size: 18px;
    width: auto;
    height: auto;
    background-color: #191920;
    padding: 25px 15px;
}

.swiper-button-prev{
    left: 50px;
}

.swiper-button-next{
    right: 50px;
}

.swiper-button-prev:before{
    content: "\e902";
    font-family: 'icomoon' !important;
}

.swiper-button-next:before{
    content: "\e903";
    font-family: 'icomoon' !important;
}

.swiper-button-prev:hover:not(.swiper-button-disabled), .swiper-button-next:hover:not(.swiper-button-disabled){
    background-color: $primary;
}


.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled{
    opacity: 0.15;
}

/**!  Swiper slide-main **/
.typer{
    display: block;
}
#swiper-main{
    .swiper-button-prev, .swiper-button-next{
        display: none;
    }
}
.swiper-slide-main{
    position: relative;
    padding-bottom: 34.5%;
    overflow: hidden;

    h3{
        font-weight: 300;
        padding-bottom: 40px;
        position: relative;
        margin-bottom: 60px;

        &:after{
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            height: 1px;
            width: 105px;
            background-color: $primary;
        }
    }

    strong{
        color: $primary;
    }

    .swiper-slide-bg{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-size: cover;
        background-position: 50%;
    }

    .swiper-slide-content{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
    }

    .slide-content{
        opacity: 0;
        transform: translateY(60px);
        transition: all 0.4s;
    }

   
    
}

.swiper-slide-active{
    .slide-content{
        opacity: 1;
        transform: translateY(0px);
    }
}

/**!  Swiper quotes **/
.swiper-text{
    .swiper-slide-quotes{
        background-color: $boxed-bg;
    }

    .swiper-slide{
        opacity: 0!important;
    }
    .swiper-slide.swiper-slide-active{
        opacity: 1!important;
    }

    .swiper-pagination{
        top: 250px;
        left: 25%;
        margin-left: -190px;
        padding-left: 120px;
        bottom: auto;
        width: auto;
        z-index: 44;
    }

    .swiper-pagination-bullet{
        width: 20px;
        height: 20px;
        background-color: $primary;
        border-radius: 0px;
        opacity: 1;
    }

    .swiper-pagination-bullet-active{
        background-color: #fff;
    }


    .boxed-title{
        transition: all 0.4s;
        opacity: 0;
        transform: translateX(-100px);
    }

    h5{
        opacity: 0;
        transition: all 0.4s 0.2s;
        transform: translateX(-50px);
    }

    .swiper-slide-active{
        .boxed-title{
            opacity: 1;
            transform: translateX(0px);
        }
    
        h5{
            opacity: 1;
            transform: translateX(0px);
        }
    }
}


/**!  Swiper fw **/
.swiper-fw-wrapper{
    position: relative;
    .swiper-button-prev{
        left: 5px;
    }
    
    .swiper-button-next{
        right: 5px;
    }
}

@media screen and (max-width: 1599px) {
    .swiper-button-next{
        right: 5px;
    }
    .swiper-button-prev{
        left: 5px;
    }

    .swiper-slide-main .slide-content{
        padding-left: 70px;
    }
    .section-letter-m .letter{
        width: 30%;
        transform: translateX(-35%);
    }

    .letter-home-l .letter{
        width: 30%;
        transform: translateX(-35%);
    }


    .advategas-item{
        height: 100%;
        padding: 30px 20px;
        
        .adventages-icon{
            margin-bottom: 25px;
            font-size: 62px;
        }
    }
}

@include media-breakpoint-down(xl) { 

}

@include media-breakpoint-down(lg) { 
    .section-title .title-label{
        display: none;
    }

    .section-solution  .section-title{
        padding: 120px 0;
    }

    .solution-item .solution-content{
        padding: 25px 0px;
    }

    .fw-row{
        transform: translateY(0px);
    }

    .letter{
        display: none;
    }

    .boxed-primary{
        margin-left: 15%;
    }

    .swiper-slide-main{
        padding-bottom: 62.5%;
    }

    .wow {
        visibility: visible !important;
        animation: none !important;
    }

    .solution-single-nav {
        top: 90px;
    }
    
}

@include media-breakpoint-down(md) { 
    .boxed-under{
        display: none;
    }

    .boxed-title{
        margin-left: 0;
    }

    .boxed-primary{
        margin-left: 0;
        width: 100%;
        max-width: 100%;
        padding: 65px 80px;
    }

    .solution-row{
        flex-direction: column;
    }

    .solution-row .solution-column:nth-child(1), .solution-row .solution-column:nth-child(2){
        padding-top: 0;
        transform: translateY(0px)
    }
    .section-solution .section-title{
        padding: 20px 0;
    }

    .solution-row .solution-column{
        padding: 0;
    }

    .section-advantages .section-title{
        align-items: flex-start;
    }

    .fw-image-wrapper-outer{
        display: none;
    }

    .fw-menu{
        position: static;
        transform: translateY(0%);
        margin-top: 40px;
    }

    .fw-row{
        padding-left: 0;
    }

    .fw-menu h4, .fw-menu h6{
        white-space: normal;
    }

    .section.section-advantages{
        margin-bottom: 40px;
    }

    .section-solution{
        padding-top: 40px;
    }

    .boxed-secoundary-wrapper{
        padding: 40px 0;
    }

    .section-letter-m{
        padding-top: 0;
        margin-top: 20px;
    }

    .swiper-slide-main .swiper-slide-content{
        position: relative;
        padding: 120px 70px;
        
        .slide-content{
            padding-left: 0;
        }
    }

    .swiper-slide-main h3{
        font-size: 28px;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }

    .swiper-slide-main{
        padding-bottom: 0;
    }

    .boxed-secoundary{
        margin-left: 0;
        padding: 65px 80px;
    }

    .swiper-text .swiper-pagination{
        top: 25px;
        left: 0;
        padding-left: 80px;
        margin-left: 0;
    }

    .section-letter-l .boxed-secoundary-wrapper{
        padding-bottom: 20px;
    }

    .boxed-contact{
        margin-left: 0;
        width: 100%;
    }

    .boxed-primary .boxed-contact{
        flex-direction: column;
    }

    .boxed-contact .boxed-list-contact:nth-child(1), .boxed-contact .boxed-list-contact:nth-child(2){
        width: 100%;
        margin: 0px 0;
    }

    .boxed-list-contact{
        padding: 20px 0;
    }

    .boxed-small.boxed-secoundary .boxed-title{
        margin-left: 0;
    }

    .boxed-small .boxed-title{
        margin-left: 0;
    }
    
    .btn-boxed {
        position: relative;
        right: 0px;
        bottom: 0;
        transform: translateY(0%);
    }

    .section-scroll{
        padding: 40px 20px;
    }
}


@include media-breakpoint-down(sm) { 
    .boxed-primary, .boxed-secoundary{
        padding: 60px 20px;
    }

    .swiper-text .swiper-pagination{
        padding-left: 20px;
    }
    .page-header{
        padding-top: 70px;
    }
    .page-header .page-header-title{
        margin-bottom: 20px;
    }

    .boxed-small h2{
        font-size: 24px;
    }

    .page-title-arrow{
        padding: 0 60px;
        h2{
            font-size: 26px;
        }
    }

    .solution-single-nav {
        top: 50px;
    }

    /**!  Swiper fw **/
    .swiper-fw-wrapper{
        position: relative;
        .swiper-button-prev{
            left: 0px;
            display: none;
        }
    
        .swiper-button-next{
            right: 0px;
            display: none;
        }
    }

    .boxed-secoundary{
        //padding-left: 80px;
        //padding-right: 80px;
    }

    .advategas-item{
        height: 100%;
        padding: 40px 20px;
        
        .adventages-icon{
            margin-bottom: 25px;
            font-size: 62px;
        }
    }
}







