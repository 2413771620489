// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px
);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1400px
);

$theme-colors: (
  "primary": #e93339,
  "secoundary": #191920,
);

$border-color: #303037;
$primary: #e93339;
$secoundary: #191920;
$white: rgba(255, 255, 255, 0.2);
$boxed-bg: rgba(0, 0, 0 ,0.3);
$input-placeholder-color: #fff;